.button {
    margin-top: auto;
}

.backButton {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    width: fit-content;
    padding: 8px 0;
    font-weight: 700;
    color: #A0AEFF;
}