.townWrapper {
    position: relative;
    max-height: calc(100vh - 154px);
    min-height: calc(100vh - 154px);
    overflow-y: auto;
    margin-bottom: 8px;
    padding: 0 8px;
}

.townTabs {
    position: sticky;
    top: 0;
    align-items: center;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-weight: 700;
    margin-bottom: 16px;
    background: #0D1533;
    z-index: 1;
}
.townTabs > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 4px 16px;
    color: rgba(255, 255, 255, 0.60);
    border-bottom: 3px solid transparent;
}

.townTabs > div img {
    width: 20px;
    height: 20px;
    opacity: 0.4;
}

.townTabs .activeTab {
    border-color: rgba(160, 174, 255, 0.60);
    color: white;
    background: linear-gradient(180deg, rgba(160, 174, 255, 0.00) 0%, rgba(160, 174, 255, 0.16) 100%);
}

.townTabs .activeTab img {
    opacity: 1;
}

.townContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}
.top div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.top div:first-of-type {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.top img {
    width: 24px;
    height: 24px;
}

.items {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.assetItemWrapper {
    padding: 12px;
    min-width: 40%;
}

.buttonBuy {
    font-size: 10px;
}

.divider {
    position: relative;
    display: inline-block;
    margin: 0 8px;
    width: 1px;
}
.divider:after {
    position: absolute;
    content: '';
    background: white;
    height: 16px;
    width: 1px;
    top: -12px;
    left: 0;
}
