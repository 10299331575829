.footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.footerItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 16px;
    background: rgba(160, 174, 255, 0.16);
    padding: 8px 12px;
    font-weight: 700;
    width: fit-content;
    gap: 6px;
    pointer-events: none;
}

.footerItem img {
    width: 24px;
    height: 24px;
}

.boostButton {
    padding: 8px 12px;
    font-weight: 700;
    border-radius: 16px;
    border: 1px solid #A0AEFF;
    background: rgba(160, 174, 255, 0.60);
    cursor: pointer;
}

.timer {

}
.timer span {
    width: 50px;
}
