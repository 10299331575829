.leaderItem {
    position: relative;
    padding: 8px 12px;
    display: grid;
    grid-template-columns: 40px auto auto;
    grid-gap: 8px;
    align-items: center;
    border-bottom: 1px solid rgba(160, 174, 255, 0.16);
}
.leaderItem:last-of-type {
    border: none;
}

.leaderItem_current {
    border-radius: 16px;
    padding: 16px 28px;
    width: calc(100% - 28px);
    margin-left: -14px;
    background: rgba(47, 58, 101, 0.88);
    margin-top: -1px;
    border: none;
    position: sticky;
    bottom: 0;
    backdrop-filter: blur(4px);
}
.leaderItem_current .rank, .leaderItem_current .name {
    color: #A0AEFF;
}

.rank {
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.88);
    border: 1px solid transparent;
    width: fit-content;
}

.rankValue {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin: 2px;
    border: 1px solid transparent;
}

.rank-1 {
    background: #FEDA2C;
    border-color: black;
}
.rank-1 .rankValue {
    background: #F4B74A;
    border-color: black;
    color: #0D1533!important;
}

.rank-2 {
    background: #999CAA;
    border-color: black;
}
.rank-2 .rankValue {
    background: #E9EBF1;
    border-color: black;
    color: #0D1533!important;
}

.rank-3 {
    background: #AA936F;
    border-color: black;
}
.rank-3 .rankValue {
    background: #D6CAB8;
    border-color: black;
    color: #0D1533!important;
}

.nameWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    margin-right: 12px;
    background-size: contain;
    border-radius: 50%;
    background-color: rgba(160, 174, 255, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.name {
    overflow: hidden;
    color: rgba(255, 255, 255, 0.88);
    text-overflow: ellipsis;
}

.points {
    font-weight: 700;
    text-align: right;
}