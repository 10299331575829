.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.headerItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 16px;
    background: rgba(160, 174, 255, 0.16);
    height: 48px;
}
.headerItem:first-child {
    font-weight: 700;
}
.headerItem:last-child {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
}
.headerItemAvatar {
    max-width: 32px;
    max-height: 32px;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    background-size: contain;
    border-radius: 50%;
    background-color: rgba(160, 174, 255, 0.16);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 28px;
}
.headerItemPic {
    margin-left: 12px;
    height: 30px;
}
.headerMinimal {
    justify-content: flex-end;
}
.headerMinimal .headerItem:last-of-type {
    display: none;
}
.headerMinimal .headerItem:first-of-type {
    background: none;
    width: auto;
    flex-direction: row-reverse;
}
.headerMinimal .headerItemAvatar {
    margin-right: 0;
    margin-left: 12px;
}