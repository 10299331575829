.App {
    text-align: center;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 390px;
    margin: 0 auto;
    padding: 8px;
    overflow: hidden;
}
