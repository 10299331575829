.leadersInput {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid rgba(160, 174, 255, 0.16);
    margin-bottom: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.leadersInput div {
    color: rgba(255, 255, 255, 0.88);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
}

.leadersInput img {
    cursor: pointer;
}
