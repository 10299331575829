.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 8px;
    background: #FFBE17;
    color: #0D1533;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    gap: 8px;
    cursor: pointer;
    text-decoration: none;
}

.s {
    font-size: 14px;
    line-height: 22px;
    padding: 4px;
    border-radius: 40px;
    width: 100%;
}

.disabled {
    background: rgba(47, 58, 101, 0.88);
    color: rgba(255, 255, 255, 0.16);
    cursor: not-allowed;
}

.button img {
    width: 16px;
    height: 16px;
}

.text {
    background: transparent;
    color: white;
    cursor: default;
}

.loader {
    width: 16px;
    height: 16px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.success {
    background: rgba(23, 196, 103, 0.70);
    color: white;
}
.success.disabled {
    background: rgba(23, 196, 103, 0.70);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}