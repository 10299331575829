.incomeWrapper {
    max-height: calc(100vh - 154px);
    min-height: calc(100vh - 154px);
    overflow-y: auto;
    margin-bottom: 8px;
    padding: 0 8px;
}
.incomeHeaderItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-weight: 700;
    margin-bottom: 16px;
}

.incomeHeaderItemAvatar {
    height: 32px;
    margin-left: 12px;
}

.incomeBalance {
    display: flex;
    padding: 8px 16px 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: rgba(253, 155, 255, 0.60);
    box-shadow: 0 4px 24px 0 rgba(109, 75, 133, 0.64);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width: max-content;
    margin: 0 auto 16px;
}
.incomeBalance div:nth-child(2) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}
.incomeBalance div:last-child {
    height: 31px;
}

.incomeTypesBalance {
    display: flex;
    flex-direction: row;
}

.incomeTypesBalanceItem {
    display: flex;
    padding: 8px 8px 16px 4px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
}

.incomeTypesBalanceItem img {
    width: 20px;
    height: 20px;
}

.incomeTypesBalanceItem div:first-of-type {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.incomeTypesBalanceItem div:last-of-type {
    font-weight: 700;
}

.incomeCityTitle {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.incomeCityTitle img {
    width: 24px;
}

.incomeCitySubtitle {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.60);
    border-radius: 8px;
    background: rgba(160, 174, 255, 0.08);
}
.incomeCityItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border-bottom: 1px solid rgba(160, 174, 255, 0.16);
}
.incomeCityItem:last-of-type {
    border: none;
}
.incomeCityItem div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.incomeCityItem > div:last-of-type {
    font-weight: 700;
}
.incomeCityItem img {
    width: 24px;
    height: 24px;
}
.incomeCityDivider {
    margin-top: 16px;
}

.emptyBlock div:first-of-type {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.emptyBlock div:last-of-type {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.60);
}

.emptyBlock img {
    width: 32px;
    height: 32px;
}