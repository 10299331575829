.bonusBlock {
    border-radius: 8px;
    background: rgba(160, 174, 255, 0.16);
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 13px 16px;
    gap: 16px;
    font-weight: 700;
}
.bonusBlock img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
}
.bonusBlockContent {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.bonusBlockContent div:first-of-type {
    font-weight: 700;
}
.bonusBlockContent div:last-of-type {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
}

.bonusBlock:last-of-type {
    margin-bottom: 0;
}
.bonusBlock > div:last-of-type {
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin-left: auto;
    margin-right: 0;
}
.bonusBlock > div:last-of-type img {
    max-width: 100%;
    max-height: 100%;
    margin-top: 8px;
}
.bonusBlock_completed {
    background: rgba(23, 196, 103, 0.16);
}