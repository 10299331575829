.leadersBlockHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.leadersBlockHeader > div:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    gap: 8px;
    min-width: max-content;
}
.leadersBlockHeader > div:nth-of-type(2) img {
    width: 20px;
}

.incomeCityTitle {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.incomeCityTitle img {
    width: 32px;
}