.leadersWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: calc(100vh - 154px);
    min-height: calc(100vh - 154px);
    overflow-y: auto;
    margin-bottom: 8px;
    padding: 0 8px;
    border-radius: 16px;
}
.leadersBlock {
    border-radius: 16px;
    background: rgba(160, 174, 255, 0.16);
    padding: 8px;
    gap: 8px;
}

.incomeCitySubtitle {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.60);
    border-radius: 8px;
    background: rgba(160, 174, 255, 0.08);
}
.incomeCityItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    padding: 8px 12px;
    border-radius: 8px;
    background: rgba(160, 174, 255, 0.16);
}
.incomeCityItem div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.incomeCityItem div:last-of-type {
    font-weight: 700;
}

.leadersInput {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid rgba(160, 174, 255, 0.16);
    margin-bottom: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.leadersInput div {
    color: rgba(255, 255, 255, 0.88);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
}

.leadersInput img {
    cursor: pointer;
}

.tableHeader {
    display: grid;
    grid-template-columns: 40px auto auto;
    grid-gap: 8px;
    align-items: center;
    color: rgba(255, 255, 255, 0.60);

    border-radius: 8px;
    background: rgba(160, 174, 255, 0.08);
    padding: 8px 12px;

    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
}
.tableHeader div:last-of-type {
    text-align: right;
}

.blockWrapper {
    padding-bottom: 0;
}

.button {
    margin-top: auto;
}