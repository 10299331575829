.mainContainer {
    max-height: calc(100vh - 202px);
    min-height: calc(100vh - 202px);
    margin-bottom: 8px;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    user-select: none;
}
