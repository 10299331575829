.container {
    position: absolute;
    max-width: 390px;
    width: 100%;
    height: 65vh;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.container img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.disabledItem {
    opacity: 40%;
}
