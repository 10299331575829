.minerWrapper {
    position: relative;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    transition: 100ms;
    will-change: transform;
}

.minerWrapper img:not(.minerWrapperBGg) {
    max-width: 100%;
    max-height: 100%;
    transition: width 300ms;
}

.minerWrapper.disabled .minerWrapperPic {
    filter: blur(2px);
    opacity: .4;
}

.minerWrapper.disabled .miner {
    filter: blur(1px);
}
.minerWrapperPic, .minerPic {
    position: relative;
    max-width: 100%;
}

.turboEffect {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 9px);
    border-radius: 30px;
    border: 8px solid rgba(255, 139, 254, 0.80);
    filter: blur(8px);
    left: 50%;
    transform: translate(-50%, -6px);
    opacity: 0;
    transition: opacity 1s;
}
.turboEffect2 {
    filter: blur(5px);
}
.turboEffect3 {
    border: 2px solid #FAABFF;
    filter: none;
    transform: translate(-50%, 0);
}
/*.clickedEffect {*/
/*    border: 2px solid rgba(249, 156, 255, 0.50);*/
/*    opacity: 1;*/
/*    filter: none;*/
/*    transform: translate(-50%, -4px);*/
/*}*/

.turboActive {
    opacity: 1;
}

.miner {
    position: absolute;
    top: 0;
}

.minerWrapperBGg {
    position: absolute;
    filter: blur(34px);
    max-height: 180%;
    max-width: 200%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.minerPic {
    max-width: 90% !important;
    top: 12px;
    position: relative;
}