.boostWrapper {
    max-height: calc(100vh - 154px);
    min-height: calc(100vh - 154px);
    overflow-y: auto;
    margin-bottom: 8px;
    padding: 0 8px;
}

.blockWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
}

.minerTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}
.minerTopLeft, .minerTopRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.minerTopLeft {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.minerTopRight {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.minerTopRight span {
    font-weight: 700;
}
.minerTopRight img {
    width: 20px;
    height: 20px;
}

.miner {
    padding: 0 24px;
}