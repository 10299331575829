.wrapper {
    position: absolute;
    width: 100%;
    background: #0D1533;
    height: 100vh;
    min-height: 100vh;
    z-index: 2;
    top: 0;
    left: 0;
}

.body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.startBg {
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.logo {
    position: relative;
    top: -100px;
    max-width: 80%;
    opacity: 0;
    animation: fadeIn 1.5s forwards;
}

.text {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.48px;
    color: white;
    opacity: 0;
    animation: fadeIn 1.5s forwards;
    max-width: 240px;
    text-align: center;
}

.textDesktop {
    font-size: 18px;
    line-height: 24px;
}

.gradient {
    position: absolute;
    top: calc(50% - 100px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    height: 400px;
    border-radius: 250px;
    background: linear-gradient(270deg, rgba(255, 0, 153, 0.24) 0%, rgba(97, 0, 255, 0.24) 100%);
    filter: blur(50px);
}

@keyframes fadeIn {
    100% {
        opacity: 1;
    }
}
