.coinWrapper {
    position: absolute;
    cursor: pointer;
    height: 70px;
    width: 70px;
    background-image: url('../../assets/images/coin-miner-optimized.png');
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 220px;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    will-change: transform, opacity;
    --offX: 0;
    --offY: 0;
    transform: translateY(var(--offY)) translateX(var(--offX));
}
.coinWrapper.anim {
    animation: anim 1s ease;
}
@keyframes anim {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-250px) translateX(var(--offX)) scale(.7) rotate(50deg);
    }
}
